import { LoggingLevel } from "src/app/_shared/_classes/logging-level";
import { Environment } from "./_interfaces/environment";

export const environment: Environment = {
  production: true,
  stage: 'dev',
  stdLang: 'en',
  enableDebug: true,
  logging: {
    level: LoggingLevel.Error
  },
  tenant: {
    abbreviation: 'OCT'
  },
  api: {
    url: 'https://azureapi-dev.mtu-online.com/api/',
    scopes: ['api://567defc2-1fab-4bd9-aad5-54c070c646b5/access_as_user']
  },
  msal: {
    clientId: 'f8a4616a-2d13-4a80-94a8-7955b0072e58',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: '/'
  }
};
